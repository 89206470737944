<template>
  <div class="container">
    <h4 class="text-center">Приказы ППС (прикрепление преподавателей)</h4>

    <PrimePreloader v-if="loading"/>

    <div v-else>
      <div class="card mt-4">
        <div class="card-body">
          <div class="card-title mb-4">
            <div class="row">
              <div class="col-12 ml-3">
                <h2 class="d-block"
                    style="font-size: 1.3rem; font-weight: bold;">Информация о приказе</h2>
              </div>
            </div>
          </div>

          <div>
            <p><strong>Номер приказа:</strong>
              {{ ordersList.name }} </p>
            <p><strong>Текст приказа (на русском):</strong>
              {{ ordersList.text_ru }}</p>
            <p><strong>Текст приказа (на казахском):</strong>
              {{ ordersList.text_kz }}</p>
            <p><strong>Текст приказа (на английском):</strong>
              {{ ordersList.text_en }}</p>
            <p><strong>Текст основания (на русском):</strong>
              {{ ordersList.basis_ru }}</p>
            <p><strong>Текст основания (на казахском):</strong>
              {{ ordersList.basis_kz }}</p>
            <p><strong>Текст основания (на английском):</strong>
              {{ ordersList.basis_en }}</p>
          </div>
        </div>
      </div>

      <Dropdown
          class="mt-3"
          v-model="selectedPpsId"
          style="width: 100%"
          :options="ppsSearchList"
          optionLabel="fullname"
          optionValue="user_id"
          placeholder="Добавить преподавателя"
          :filter="true"
          @filter="searchByName($event.value)"
          @change=addPpsOrderMember($event.value)
      />

      <h4 class="mt-3 mb-3">Список преподавателей</h4>
      <p v-for="(pps, index) in ppsMembers" :key="index">
        {{ pps.fullname }}
        (<span style="color: blue; text-decoration: underline; cursor: pointer;"
               @click="deleteMember(pps.user_id)">Удалить</span>)
      </p>
      <div class="text-center" v-if="ordersList.is_confirm_status == null && decryptCheckRole('rector')">
        <Button label="Утвердить" class="p-button-success me-1" @click="confirmOrder(1)" :loading="loadingConfirm"/>
        <Button label="Отказать" class="p-button-danger" @click="confirmOrder(2)" :loading="loadingConfirm"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

export default {
  name: 'TeachersOrders',
  data() {
    return {
      loading: true,
      selectedPpsId: null,
      order_id: null,

      loadingConfirm: false,
    }
  },
  computed: {
    ...mapState('teachersOrders', ['ordersTypes', 'ordersList', 'ppsSearchList', 'ppsMembers'])
  },
  watch: {},
  methods: {
    decryptCheckRole,
    ...mapActions('teachersOrders', ['GET_ORDERS_TYPES', 'GET_ORDERS', 'GET_PPS_BY_NAME', 'POST_PPS_MEMBER',
      'GET_PPS_MEMBERS', 'DELETE_PPS_MEMBER', 'CONFIRM_PPS_ORDER']),

    async confirmOrder(status) {
      this.loadingConfirm = true
      if (await this.CONFIRM_PPS_ORDER({order_id: this.order_id, status_order: status})) {
        this.$message({title: 'Успешно выполнено!'})
        await this.GET_ORDERS(this.order_id)
      }
      this.loadingConfirm = false
    },

    // checkRole(i) {
    //   const roles = JSON.parse(getCookie('ROLES')) || []
    //   if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    //   }
    //   return roles.includes(i)
    // },

    async searchByName(name) {
      if (name.length > 2) {
        console.log('+')
        await this.GET_PPS_BY_NAME(name)
      }
    },

    async addPpsOrderMember(user_id) {
      this.loading = true
      this.selectedPpsId = null

      let data = {
        pps_user_id: user_id,
        pps_order_id: this.order_id
      }

      if (await this.POST_PPS_MEMBER(data)) {
        this.$message({title: 'Преподаватель успешно добавился!'})
        await this.GET_PPS_MEMBERS(this.order_id)
        this.loading = false
      }
    },

    async deleteMember(user_id) {
      this.loading = true
      if (await this.DELETE_PPS_MEMBER({pps_order_id: this.order_id, pps_user_id: user_id})) {
        await this.GET_PPS_MEMBERS(this.order_id)
        this.$message({title: 'Преподаватель успешно удален!'})
      }
      this.loading = false
    }
  },
  async mounted() {
    this.order_id = this.$route.query.order_id
    await this.GET_ORDERS_TYPES()
    await this.GET_ORDERS(this.order_id)
    await this.GET_PPS_MEMBERS(this.order_id)
    this.loading = false
  },
}
</script>